// **  Initial State
const initialState = {
  clientsComplaints: {},
  clientsComplaint: {},
  isDeletedSuccessfully: false
}

const complaintsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENT_COMPLAINTS':
      return {
        ...state,
        clientsComplaints: action.data
      }
    case 'GET_CLIENT_COMPLAINT':
      return {
        ...state,
        clientsComplaint: action.data
      }
    case 'DELETE_CLIENT_COMPLAINT_SUCCESS':
      return {
        ...state,
        isDeletedSuccessfully: true
      }
    case 'CLEAR_DELETE_SUCCESS':
      return {
        ...state,
        isDeletedSuccessfully: false
      }
    default:
      return state
  }
}

export default complaintsReducer

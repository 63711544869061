// **  Initial State
const initialState = {
    moderators: {},
    currentModerator: {}
}

const moderatorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MODERATORS':
            return {
                ...state,
                moderators: action.data
            }
        case 'GET_CURRENT_MODERATOR': 
            return {
                ...state,
                currentModerator: action.data
            }
        default:
            return state
    }
}

export default moderatorsReducer
  
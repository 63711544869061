// **  Initial State
const initialState = {
  clients: {},
  currentClient: {}
}

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENTS':
      return {
        ...state,
        clients: action.data
      }
    case 'GET_CLIENT':
      return {
        ...state,
        currentClient: action.data
      }
    default:
      return state
  }
}

export default clientsReducer

// **  Initial State
const initialState = {
  data: { total: 0, data: [] },
  scroll: {},
  chatId: 0
}

const chatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.data
      }
    case 'SET_SCROLL':
      return {
        ...state,
        scroll: action.data
      }
    case 'SET_CHAT_ID':
      return {
        ...state,
        chatId: action.data
      }
    default:
      return state
  }
}

export default chatsReducer

// **  Initial State
const initialState = {
    articles: {},
    currentArticle: {}
}

const articlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ARTICLES':
            return {
                ...state,
                articles: action.data
            }
        case 'GET_ARTICLE': 
            return {
                ...state,
                currentArticle: action.data
            }
        default:
            return state
    }
}

export default articlesReducer
  
export const GET_MEAT_FARMS_PENDING = 'GET_MEAT_FARMS_PENDING'
export const GET_MEAT_FARMS_SUCCESS = 'GET_MEAT_FARMS_SUCCESS'
export const GET_MEAT_FARMS_FAILED = 'GET_MEAT_FARMS_FAILED'
export const CLEAR_MEAT_FARMS_LOADING_ERROR = 'CLEAR_MEAT_FARMS_LOADING_ERROR'

export const GET_MEAT_FARM_BY_ID_PENDING = 'GET_MEAT_FARM_BY_ID_PENDING'
export const GET_MEAT_FARM_BY_ID_SUCCESS = 'GET_MEAT_FARM_BY_ID_SUCCESS'
export const GET_MEAT_FARM_BY_ID_FAILED = 'GET_MEAT_FARM_BY_ID_FAILED'
export const CLEAR_MEAT_FARM_LOADING_ERROR = 'CLEAR_MEAT_FARM_LOADING_ERROR'
export const CLEAR_MEAT_FARM_STATE = 'CLEAR_MEAT_FARM_STATE'

export const DELETE_MEAT_FARM_PENDING = 'DELETE_MEAT_FARM_PENDING'
export const DELETE_MEAT_FARM_SUCCESS = 'DELETE_MEAT_FARM_SUCCESS'
export const DELETE_MEAT_FARM_FAILED = 'DELETE_MEAT_FARM_FAILED'
export const CLEAR_MEAT_FARM_DELETING_RESULT = 'CLEAR_MEAT_FARM_DELETING_RESULT'

export const ADD_MEAT_FARM_PENDING = 'ADD_MEAT_FARM_PENDING'
export const ADD_MEAT_FARM_SUCCESS = 'ADD_MEAT_FARM_SUCCESS'
export const ADD_MEAT_FARM_FAILED = 'ADD_MEAT_FARM_FAILED'
export const CLEAR_MEAT_FARM_ADDING_RESULT = 'CLEAR_MEAT_FARM_ADDING_RESULT'

export const EDIT_MEAT_FARM_PENDING = 'EDIT_MEAT_FARM_PENDING'
export const EDIT_MEAT_FARM_SUCCESS = 'EDIT_MEAT_FARM_SUCCESS'
export const EDIT_MEAT_FARM_FAILED = 'EDIT_MEAT_FARM_FAILED'
export const CLEAR_MEAT_FARM_EDITING_RESULT = 'CLEAR_MEAT_FARM_EDITING_RESULT'
export const FETCH_COORDINATES_BY_ADDRESS_SUCCESS = 'FETCH_COORDINATES_BY_ADDRESS_SUCCESS'
export const FETCH_ADDRESS_BY_COORDINATES_SUCCESS = 'TCH_ADDRESS_BY_COORDINATES_SUCCESS'

export const CHANGE_MEAT_FARM_FIELD = 'CHANGE_MEAT_FARM_FIELD'

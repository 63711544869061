// **  Initial State
const initialState = {
  announcements: {},
  currentAnnouncement: {
    headPrice: '0',
    kgPrice: '0',
    contactType: []
  }
}

const announcementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ANNOUNCEMENTS':
      return {
        ...state,
        announcements: action.data
      }
    case 'GET_CURRENT_ANNOUNCEMENT':
      return {
        ...state,
        currentAnnouncement: action.data
      }
    default:
      return state
  }
}

export default announcementsReducer
  
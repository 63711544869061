import {
  GET_MEAT_FARMS_PENDING,
  GET_MEAT_FARMS_SUCCESS,
  GET_MEAT_FARMS_FAILED,
  GET_MEAT_FARM_BY_ID_PENDING,
  GET_MEAT_FARM_BY_ID_SUCCESS,
  GET_MEAT_FARM_BY_ID_FAILED,
  CLEAR_MEAT_FARMS_LOADING_ERROR,
  CLEAR_MEAT_FARM_LOADING_ERROR,
  DELETE_MEAT_FARM_PENDING,
  DELETE_MEAT_FARM_SUCCESS,
  DELETE_MEAT_FARM_FAILED,
  CLEAR_MEAT_FARM_DELETING_RESULT,
  ADD_MEAT_FARM_PENDING,
  ADD_MEAT_FARM_SUCCESS,
  ADD_MEAT_FARM_FAILED,
  CLEAR_MEAT_FARM_ADDING_RESULT,
  EDIT_MEAT_FARM_PENDING,
  EDIT_MEAT_FARM_SUCCESS,
  EDIT_MEAT_FARM_FAILED,
  CLEAR_MEAT_FARM_EDITING_RESULT,
  CHANGE_MEAT_FARM_FIELD,
  CLEAR_MEAT_FARM_STATE,
  FETCH_COORDINATES_BY_ADDRESS_SUCCESS,
  FETCH_ADDRESS_BY_COORDINATES_SUCCESS
} from '../../constants/meat-farms'
import { DEFAULT_PAGE_LIMIT } from '../../constants'
import { isEmailValid } from '../../../utility/Utils'

const initialState = {
  meatFarms: {
    total: 0,
    skip: 0,
    data: []
  },
  meatFarmsLoading: 'idle',
  meatFarmsLoadingError: null,

  meatFarm: {
    name: '',
    status: 'commercial',
    breeds: [],
    logoId: null,
    phone: '',
    email: '',
    isEmailInvalid: false,
    address: '',
    latitude: null,
    longitude: null,
    link: '',
    clientId: null
  },
  meatFarmLoading: 'idle',
  meatFarmLoadingError: null,

  deleting: 'idle',
  deletingSuccess: false,
  deletingError: null,

  adding: 'idle',
  addingSuccess: false,
  addingError: null,

  editing: 'idle',
  editingSuccess: false,
  editingError: null
}

const meatFarmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEAT_FARMS_PENDING:
      return {
        ...state,
        meatFarmsLoading: 'pending',
        meatFarmsLoadingError: null
      }

    case GET_MEAT_FARMS_SUCCESS:
      const response = action.payload

      return {
        ...state,
        meatFarmsLoading: 'idle',
        meatFarms: {
          total: response.total,
          skip: response.skip,
          data: response.data
        }
      }

    case GET_MEAT_FARMS_FAILED:
      return {
        ...state,
        meatFarmsLoading: 'error',
        meatFarmsLoadingError: action.payload
      }

    case CLEAR_MEAT_FARMS_LOADING_ERROR:
      return {
        ...state,
        meatFarmsLoadingError: null
      }

    case GET_MEAT_FARM_BY_ID_PENDING:
      return {
        ...state,
        meatFarmLoading: 'pending',
        meatFarmLoadingError: null,
        meatFarm: initialState.meatFarm
      }

    case GET_MEAT_FARM_BY_ID_SUCCESS:
      return {
        ...state,
        meatFarmLoading: 'idle',
        meatFarm: {
          ...action.payload,
          breeds: action.payload.breeds.map(breed => breed.id)
        }
      }

    case GET_MEAT_FARM_BY_ID_FAILED:
      return {
        ...state,
        meatFarmLoading: 'error',
        meatFarmLoadingError: action.payload
      }

    case CLEAR_MEAT_FARM_LOADING_ERROR:
      return {
        ...state,
        meatFarmLoadingError: null
      }

    case CLEAR_MEAT_FARM_STATE:
      return {
        ...state,
        meatFarm: initialState.meatFarm
      }

    case DELETE_MEAT_FARM_PENDING:
      return {
        ...state,
        deleting: 'pending',
        deletingSuccess: false,
        deletingError: null
      }

    case DELETE_MEAT_FARM_SUCCESS:
      return {
        ...state,
        deleting: 'idle',
        deletingSuccess: true
      }

    case DELETE_MEAT_FARM_FAILED:
      return {
        ...state,
        deleting: 'idle',
        deletingError: action.payload
      }

    case CLEAR_MEAT_FARM_DELETING_RESULT:
      return {
        ...state,
        deletingSuccess: false,
        deletingError: null
      }

    case ADD_MEAT_FARM_PENDING:
      return {
        ...state,
        adding: 'pending',
        addingSuccess: false,
        addingError: null
      }

    case ADD_MEAT_FARM_SUCCESS:
      return {
        ...state,
        adding: 'idle',
        addingSuccess: true
      }

    case ADD_MEAT_FARM_FAILED:
      return {
        ...state,
        adding: 'idle',
        addingError: action.payload
      }

    case CLEAR_MEAT_FARM_ADDING_RESULT:
      return {
        ...state,
        addingSuccess: false,
        addingError: null
      }

    case EDIT_MEAT_FARM_PENDING:
      return {
        ...state,
        editing: 'pending',
        editingSuccess: false,
        editingError: null
      }

    case EDIT_MEAT_FARM_SUCCESS:
      return {
        ...state,
        editing: 'idle',
        editingSuccess: true
      }

    case EDIT_MEAT_FARM_FAILED:
      return {
        ...state,
        editing: 'idle',
        editingError: action.payload
      }

    case CLEAR_MEAT_FARM_EDITING_RESULT:
      return {
        ...state,
        editingSuccess: false,
        editingError: null
      }

    case FETCH_COORDINATES_BY_ADDRESS_SUCCESS:
      if (!state.meatFarm.address) {
        return state
      }

      const coordinatesFeatureMember = action.payload?.GeoObjectCollection?.featureMember

      if (!Array.isArray(coordinatesFeatureMember)) {
        return state
      }

      const coordinatesGeoObject = coordinatesFeatureMember[0]?.GeoObject

      const [longitude, latitude] = coordinatesGeoObject.Point.pos.split(' ')

      return {
        ...state,
        meatFarm: {
          ...state.meatFarm,
          longitude,
          latitude
        }
      }

    case FETCH_ADDRESS_BY_COORDINATES_SUCCESS:
      if (!state.meatFarm.latitude || !state.meatFarm.longitude) {
        return state
      }

      const addressFeatureMember = action.payload?.GeoObjectCollection?.featureMember

      if (!Array.isArray(addressFeatureMember)) {
        return state
      }

      const addressGeoObject = addressFeatureMember[0]?.GeoObject

      return {
        ...state,
        meatFarm: {
          ...state.meatFarm,
          address: `${addressGeoObject.description}, ${addressGeoObject.name}`
        }
      }

    case CHANGE_MEAT_FARM_FIELD:
      const change = action.payload

      if (change.field === 'phone') {
        change.value = change.value.replace(/[^0-9]+/g, '')
      }

      const isEmailInvalid =
        change.field === 'email' ? !isEmailValid(change.value) : state.meatFarm.isEmailInvalid

      return {
        ...state,
        meatFarm: {
          ...state.meatFarm,
          [change.field]: change.value,
          isEmailInvalid
        }
      }

    default:
      return state
  }
}

export const selectMeatFarms = state => state.meatFarms.meatFarms.data
export const selectMeatFarmsPage = state => state.meatFarms.meatFarms.skip / DEFAULT_PAGE_LIMIT + 1
export const selectMeatFarmsPagesCount = state => {
  return Math.ceil(state.meatFarms.meatFarms.total / DEFAULT_PAGE_LIMIT)
}
export const selectMeatFarmsTotal = state => state.meatFarms.meatFarms.total
export const selectMeatFarmsLoading = state => state.meatFarms.meatFarmsLoading
export const selectMeatFarmsLoadingError = state => state.meatFarms.meatFarmsLoadingError
export const selectMeatFarm = state => state.meatFarms.meatFarm
export const selectMeatFarmLoading = state => state.meatFarm.meatFarmLoading
export const selectMeatFarmLoadingError = state => state.meatFarm.meatFarmLoadingError
export const selectMeatFarmDeleting = state => state.meatFarms.deleting
export const selectMeatFarmDeletingSuccess = state => state.meatFarms.deletingSuccess
export const selectMeatFarmDeletingError = state => state.meatFarms.deletingError
export const selectMeatFarmAdding = state => state.meatFarms.adding
export const selectMeatFarmAddingSuccess = state => state.meatFarms.addingSuccess
export const selectMeatFarmAddingError = state => state.meatFarms.addingError
export const selectMeatFarmEditing = state => state.meatFarms.editing
export const selectMeatFarmEditingSuccess = state => state.meatFarms.editingSuccess
export const selectMeatFarmEditingError = state => state.meatFarms.editingError

export default meatFarmsReducer

// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import articles from './articles'
import news from './news'
import moderators from './moderators'
import advertisements from './advertisements'
import categories from './categories'
import complaints from './complaints'
import announcements from './announcements'
import chats from './chats'
import reviews from './reviews'
import clients from './clients'
import meatFarms from './meat-farms'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  articles,
  news,
  moderators,
  advertisements,
  categories,
  complaints,
  announcements,
  chats,
  reviews,
  clients,
  meatFarms
})

export default rootReducer

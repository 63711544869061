// **  Initial State
const initialState = {
  reviews: {},
  currentReview: {}
}

const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REVIEWS':
      return {
        ...state,
        reviews: action.data
      }
    case 'GET_REVIEW':
      return {
        ...state,
        currentReview: action.data
      }
    default:
      return state
  }
}

export default reviewsReducer

// ** Initial State
const initialState = {
    categories: [],
    preeds: []
}
  
const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CATEGORIES':
            return { 
                ...state, 
                categories: action.categories
            }
        case 'GET_PREEDS':
            return { 
                ...state, 
                preeds: action.preeds 
            }
        case 'LOAD_CATEGORIES':
            return { 
                ...state, 
                categories: [...state.categories, ...action.categories], 
                preeds: [...state.preeds, ...action.preeds]
            }
        default:
            return state
    }
}
export default categoriesReducer
  
// **  Initial State
const initialState = {
    advertisements: {},
    currentAdvertisement: {}
}

const advertisementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ADVERTISEMENTS':
            return {
                ...state,
                advertisements: action.data
            }
        case 'GET_ADVERTISEMENT': 
            return {
                ...state,
                currentAdvertisement: action.data
            }
        default:
            return state
    }
}

export default advertisementsReducer
  
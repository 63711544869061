// **  Initial State
const initialState = {
    news: {},
    currentNews: {}
}

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_NEWS':
            return {
                ...state,
                news: action.data
            }
        case 'GET_CURRENT_NEWS': 
            return {
                ...state,
                currentNews: action.data
            }
        default:
            return state
    }
}

export default newsReducer
  